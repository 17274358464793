import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { Modal } from "@mantine/core";
import NavbarItem from "./NavbarItem";
import SeacrhPost from "../../search/SearchPost";
import { FiLink } from "react-icons/fi";
import { BsInstagram, BsFacebook, BsYoutube } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const socData = [
  {
    id: 1,
    icon: <FaTelegramPlane size={30} />,
    title: "Telegram",
    color: "#229ED9",
    link: "https://t.me/yuqorichirchiq_hokimiyat",
  },
  {
    id: 2,
    icon: <BsInstagram size={30} />,
    title: "Instagram",
    color: "#C13584",
    link: "https://www.instagram.com/yuqorichirchiqtumani/?igshid=YmMyMTA2M2Y%3D",
  },
  {
    id: 3,
    icon: <BsFacebook size={30} />,
    title: "Facebook",
    color: "#4267B2",
    link: "https://www.facebook.com/people/Yuqori-Chirchiq-Tumani/pfbid02VsesZM6ccWtR9tsQABUd7mKHhmJSrddtLZ2N2zDAsEbuBDxLKWwWWjsDirQfiVSDl/?mibextid=ZbWKwL",
  },
  {
    id: 4,
    icon: <BsYoutube size={30} />,
    title: "Youtube",
    color: "#FF0000",
    link: "https://www.youtube.com/@yuqorichirchiqtumanhokimli4935",
  },
];

const Navbar = () => {
  const [opened, setOpened] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleChangeLng = (lng) => {
    i18next.changeLanguage(lng);
    i18next.reloadResources();
    localStorage.setItem("lng", lng);
    window.location.reload();
  };

  return (
    <div className="h-[60px] bg-[#38a3a5] text-white mb-[40px] ">
      <div className="max-w-[1280px] mx-auto h-full flex justify-between items-center lg:pl-0 md:pl-4 pl-6">
        <GiHamburgerMenu
          size={30}
          onClick={() => setOpened(!opened)}
          className="cursor-pointer"
        />

        <div className="flex gap-2 lg:mr-0 md:mr-6 mr-8">
          <div className="lg:block md:block hidden">
            <SeacrhPost />
          </div>
          <div className="lg:relative absolute right-0  lg:top-0 -top-[28px]">
            <p
              onClick={() => setOpen(!open)}
              className="flex items-center text-sm lg:text-[17px] lg:font-medium transition-all duration-300 cursor-pointer mt-1"
            >
              {localStorage.getItem("lng") === "uz"
                ? "O'zb"
                : localStorage.getItem("lng") === "ru"
                ? "Рус"
                : "O'zb"}
              <i
                className={`${
                  open && "rotate-180 transition-all duration-500"
                }`}
              >
                <MdKeyboardArrowDown size={20} />
              </i>
            </p>
            <div
              className={`${
                open
                  ? "absolute transition-all duration-500 bg-[#22577a] px-2 py-1 rounded translate-y-1"
                  : "-translate-y-2 hidden transition-all duration-500"
              }`}
            >
              <p
                className="cursor-pointer"
                onClick={() => {
                  handleChangeLng("uz");
                }}
              >
                O'zbekcha
              </p>
              <p
                className="cursor-pointer"
                onClick={() => {
                  handleChangeLng("ru");
                }}
              >
                Русский
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          withCloseButton={false}
          fullScreen
        >
          <div className="flex justify-end mr-[20px] mb-[20px] ">
            <GrClose
              onClick={() => setOpened(false)}
              size={20}
              className="cursor-pointer"
            />
          </div>
          <NavbarItem setOpened={setOpened} />
        </Modal>
      </div>
      <div>
        <div className="lg:block md:block hidden">
          <ul className="z-10 fixed mt-[160px]">
            {socData.map((sitem, index) => (
              <li
                style={{ backgroundColor: `${sitem.color}` }}
                className="bg-[#00afb9]  w-[160px] h-[60px] flex  items-center ml-[-100px] hover:ml-[-10px] duration-300"
                key={index}
              >
                <a
                  href={sitem.link}
                  target="_blank"
                  className="flex pl-9 gap-3 font-medium items-center w-full text-gray-50"
                >
                  <p className="w-[65px]">{sitem.title}</p>
                  {sitem.icon}
                </a>
              </li>
            ))}
            <li className="bg-[#00afb9]  w-[160px] h-[60px] flex  items-center ml-[-100px] hover:ml-[-10px] duration-300">
              <a
                href="/saytxaritasi"
                target="_blank"
                className="flex pl-5 gap-3 font-medium items-center w-full text-gray-50"
              >
                {t("Sayt xaritasi")}
                <FiLink size={30} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
