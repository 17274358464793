import React from "react";
import karta2 from "../../assets/images/karta2.jpg";
import { useTranslation } from "react-i18next";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:flex gap-6">
      <div className="flex flex-col gap-[40px] text-white ">
        <p>
          {t(
            "Yuqori chirchiq tumani — Toshkent viloyatidagi tuman. 1926-yil 29-sentyabrda tashkil etilgan. 1959-yilda Parkent tumani, 1962-yilda Boʻstonliq tumani qoʻshildi. 1964-yildan Boʻstonliq, 1979-yildan Parkent tumani ajralib chiqdi."
          )}
        </p>
        <p>
          {t(
            "Shimoli-sharqdan Boʻstonliq va Parkent tumanlari, janub va janubi-sharqdan Ohangaron tumani, janubi-gʻarbdan Oʻrta Chirchiq, gʻarbdan Toshkent shahri va Qibray tumanlari bilan chegaradosh. Maydoni 0,44 ming km². Tumanda 1 shaharcha (Yangibozor), 9 qishloq fuqarolari yigʻini (Argʻinchi, Sosbaqa, Bordonkoʻl, Jambul, Oqovul, Navroʻz, Saksonota, Surankent, Tinchlik) bor. Tuman markazi — Yangibozor shaharchasi."
          )}
        </p>
        <p>
          {t(
            "Tuman iqtisodiyoti, asosan, qishloq xoʻjaligi ishlab chiqarishiga ixtisoslashgan. Paxta tozalash, qishloq xoʻjaligi mashinalari ishlab chiqarish tajriba zavodi, bosmaxona, MTP, toshshagʻal maydalash va saralash kti, avtokorxona, qurilish tashkilotlari, 300 kichik korxona va boshqalar ishlab turibdi. 6 qoʻshma korxona faoliyat koʻrsatadi. Tumanda 5 shirkat, 830 fermer xoʻjaligi mavjud. Sugʻoriladigan yerlarda paxta, bahorikor yerlarda donchilik; shuningdek, chorvachilik, pillachilik rivojlangan."
          )}
        </p>
      </div>
      <div className="flex flex-col gap-4 mt-[20px] lg:mt-0">
        <img
          src={karta2}
          alt="region info"
          height={400}
          width={700}
          className="rounded-lg"
        />
      </div>
    </div>
  );
};

export default RegionInfo;
