import React from "react";
import AllNews from "../../pages/allNews/AllNews";
import NewsHero from "../../pages/newsHero/NewsHero";
import UsefulResources from "../../pages/usefulResources/UsefulResources";
import GoToPhoto from "../../pages/goToPhoto/GoToPhoto";
import NewsCarousel from "../../pages/newsCarousel/NewsCarousel";
import Hokim from "../../pages/hokim/Hokim";
import RegionInfo from "../../pages/regionInfo/RegionInfo";
import Test from "../../pages/test/Test";

const Home = () => {
  return (
    <div>
      <div className="lg:hidden md:hidden block text-[#22577A]">
        <Test />
      </div>
      <div className="lg:flex md:flex gap-2 mb-[20px] lg:mx-0 md:mx-4">
        <div className="lg:w-[30%] mx:w-[30%] lg:mx-0 md:mx-0 mx-6 bg-[#468faf] lg:pb-0 md:pb-0 pb-[20px] lg:mb-0 md:mb-0  mb-[40px] rounded-lg">
          <Hokim />
        </div>
        <div className="lg:w-[70%] md:w-[70%] lg:mx-0 md:mx-0 mx-6">
          <NewsCarousel />
        </div>
      </div>
      <div className="lg:flex gap-3 mb-[40px] ">
        <div className="lg:w-[70%] bg-[#468faf] p-[20px] mt-[40px] rounded-lg lg:mx-0 md:mx-4 mx-6">
          <NewsHero />
        </div>
        <div className="bg-[#468faf] p-[20px] mt-[40px] rounded-lg lg:w-[30%] lg:mx-0 md:mx-4 mx-6">
          <AllNews />
        </div>
      </div>
      <div className="bg-[#468faf] p-[50px] rounded-lg lg:mx-0 md:mx-4 mx-6 lg:block md:hidden hidden">
        <RegionInfo />
      </div>
      <GoToPhoto />
      <UsefulResources />
    </div>
  );
};

export default Home;
