import React from "react";
import { useGetNavbar } from "../../hooks/query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SaytXaritasi = () => {
  const useGetNavbarList = useGetNavbar();
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-center text-2xl font-bold">{t("Sayt xaritasi")}</h3>
      {useGetNavbarList.data?.map((item) => (
        <div className="mb-[40px]">
          <h5 className="text-2xl my-2">{item.name}</h5>
          {item.children.map((subItem) => (
            <Link to={`/ndetail/${subItem.slug}`}>
              <li className="hover:bg-sky-100">{subItem.name}</li>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SaytXaritasi;
