import { useEffect, useState } from "react";
import { useGetBudget, useGetYear } from "../../../hooks/query";
import BudgetItem from "./BudgetItem";
import { Pagination } from "@mantine/core";
import { useTranslation } from "react-i18next";

const chorak = [
  {
    chorak: "1-CHORAK",
    name: "I-CHORAK",
  },
  {
    chorak: "2-CHORAK",
    name: "II-CHORAK",
  },
  {
    chorak: "3-CHORAK",
    name: "III-CHORAK",
  },
  {
    chorak: "4-CHORAK",
    name: "IV-CHORAK",
  },
];

const Budgetmalumotlari = () => {
  const { t } = useTranslation();
  const [page, setPages] = useState(1);
  const [quarters, setQuarters] = useState("");
  const [years, setYears] = useState("");
  const useGetBudgetList = useGetBudget({
    quarter: quarters,
    year: years,
  });
  const useGetYears = useGetYear();
  const handleChangequart = (event) => {
    setQuarters(event.target.value);
  };

  const handleChange = (event) => {
    setYears(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen">
      <h3 className="text-center font-semibold text-2xl">
        {t("Budjet ma'lumotlari")}
      </h3>
      <select
        value={years}
        onChange={handleChange}
        className="px-[20px] py-[6px] mr-[10px] rounded-lg shadow-2xl"
      >
        <option selected>Yilni tanlang</option>
        {useGetYears.data?.map((option, index) => (
          <option value={option.id} key={index}>
            {option.year}
          </option>
        ))}
      </select>
      <select
        value={quarters}
        onChange={handleChangequart}
        className="px-[20px] py-[6px] mr-[10px] rounded-lg shadow-2xl"
      >
        <option selected>Chorakni tanlang</option>
        {chorak?.map((option, index) => (
          <option value={option.chorak} key={index}>
            {option.name}
          </option>
        ))}
      </select>

      {quarters === "" || years === "" ? (
        <p className="pt-[10%] text-neutral-700 text-center font-bold text-lg lg:text-2xl ">
          Ma'lomot olish yil va chorakni tanlang
        </p>
      ) : (
        <>
          <div className="bg-white mt-[20px] px-[20px] py-[20px] shadow-2xl rounded-2xl">
            {useGetBudgetList.data?.results.map((item) => (
              <BudgetItem item={item} key={item.id} />
            ))}
            {useGetBudgetList.data?.results == 0 && (
              <p>{t("Ma'lumot mavjud emas")}</p>
            )}
          </div>
          <div>
            <Pagination
              pt={28}
              pb={50}
              color="cyan"
              size="lg"
              radius="xl"
              page={page}
              onChange={setPages}
              disabled={page >= 10}
              total={useGetBudgetList.data?.total_pages}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Budgetmalumotlari;
