import React from "react";
import { useTranslation } from "react-i18next";

const SeacrhPost = () => {
  const { t } = useTranslation();
  return (
    <div>
      <form className="text-black">
        <input
          className="w-[400px] py-[4px] rounded border-none "
          placeholder={t(" Izlash...")}
          type="search"
        />
      </form>
    </div>
  );
};

export default SeacrhPost;
