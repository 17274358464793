import React from "react";
import { Link } from "react-router-dom";
import { useGetEightNews } from "../../hooks/query";
import { GrFormNextLink } from "react-icons/gr";
import AllNewsItem from "./AllNewsItem";
import { Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";

const AllNews = () => {
  const useGetEightNewsList = useGetEightNews();
  const { t } = useTranslation();
  if (useGetEightNewsList.isLoading) {
    return (
      <div className="flex justify-center left-[350px] h-[600px]">
        <Loader color="#012a4a" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div>
      <h3 className="text-center py-[30px] font-semibold text-2xl">
        {t("Barcha yangiliklar")}
      </h3>
      <div className="text-white">
        {useGetEightNewsList.data?.map((item) => (
          <AllNewsItem item={item} key={item.id} />
        ))}
      </div>
      <Link to="/all-news">
        <span className="flex gap-2 px-1 py-2 hover:bg-[#EEF2FF] duration-200 rounded-r-full items-center bg-gray-300 border-l-[3px] border-l-blue-700 w-[160px] mt-[30px]">
          <p>{t("Ko'proq yangilik")} </p>
          <GrFormNextLink size={22} />
        </span>
      </Link>
    </div>
  );
};

export default AllNews;
