import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import uz from "../../components/i18next/lng/uz.json";
import ru from "../../components/i18next/lng/ru.json";

i18next.use(initReactI18next).init({
  resources: {
    uz: {
      translation: uz,
    },
    ru: {
      translation: ru,
    },
  },
  detection: {
    order: ["path", "cookie", "htmlTag"],
    caches: ["cookie"],
  },
  lng: localStorage.getItem("lng") || "uz",
});

export default i18next;
