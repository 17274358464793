import React, { useState } from "react";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";

const AllNewsItem = ({ item }) => {
  const [readMore] = useState(false);


  let date = new Date(item.add_time);
  let dateMDY = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;
  return (
    <div>
      <Link to={`pdetail/${item.slug}`}>
      {readMore ? item.title : `${item.title.substring(0,85)} ...`}

      {/* {  item.title} */}
        </Link>
      <p className="flex gap-1 items-center justify-end text-gray-300 mt-[10px] text-[12px]">
        <BiCalendar />
        {dateMDY}
      </p>
      <div className="border-b-2 border-[#0f3460] pt-[10px] mb-[14px]"></div>
    </div>
  );
};

export default AllNewsItem;
