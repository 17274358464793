import { useGetHokim } from "../../hooks/query";
import { Link } from "react-router-dom";
import { Loader } from "@mantine/core";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Hokim = () => {
  const useGetHokimInfo = useGetHokim();
  const { t } = useTranslation();
  if (useGetHokimInfo.isLoading) {
    return (
      <div className="flex justify-center">
        <Loader color="#012a4a" size="xl" variant="bars" />
      </div>
    );
  }
  return (
    <div>
      {useGetHokimInfo.data?.map((item) => (
        <div key={item.id} className="text-white">
          <img
            src={item.images}
            alt="hokim"
            height={380}
            width={350}
            className="flex justify-center mx-auto rounded-lg mt-[20px]"
          />
          <div className="flex flex-col items-center">
            <h4 className="text-[24px] mt-[20px] ">{item.f_name}</h4>
            <p className="text-[20px] mt-[10px]">
              {t("Yuqori Chirchiq tumani hokimi")}
            </p>
          </div>
          <div className="flex items-center gap-2 justify-center mt-[20px]">
            <BsFillTelephoneFill size={18} />
            <p className="text-[18px]">+998 70 983 15 57</p>
          </div>

          <div className="flex justify-center bg-gray-300 hover:bg-[#eef2ff] duration-300 w-[200px] mx-auto lg:mt-[60px] md:mt-[30px] mt-[10px] py-3 rounded-lg">
            <Link to={`/murojaat/${item.id}`}>
              <button className="text-black">{t("Murojaat yuborish")}</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Hokim;
